import React from 'react'
import { NavWithLogoOnly } from '../../components/ui/navigation/navigation'
import Container from '../../components/ui/container'
import * as styles from './eula.module.css'

import "../base.css"

export default class Eula extends React.Component {
  render() {
    return (
      <div style={{ backgroundColor: '#ffffff' }}>
        <NavWithLogoOnly />
        <Container>
          <h2 className="text-3xl mb-8">End User License Agreement</h2>

          <EulaBlock>
            <p>Welcome to Wagetap!</p>
            <h6>1 What are you reading?</h6>
            <div>
              <p>(a) These are the terms and conditions of use (<b>T&Cs</b>), on which we grant you a right to use the cloud-based and smart device software and services provided through such software, including all instructions in hard copy or electronic form and any update, modification or release of any part of that software (<b>Wagetap Platform</b>).</p>
              <p>1.2 You can access and use the Wagetap Platform by downloading the application on a mobile device or through another platform (for example a website).</p>
              <p>1.3 These T&Cs will become binding on and from the date on which you download or access the Wagetap Platform (<b>Effective Date</b>).</p>
              <p>1.4 We will provide you with an account to enable you to access and use the Wagetap Platform (<b>Account</b>), which will be available until the date on which your account is terminated by you or terminated by us (which we may do at any time and without notice to you) (the <b>Term</b>). </p>
              <p>1.5 Please refer to clause 22 to see what happens on termination.</p>
              <p>1.6 The following clauses will survive termination and/or expiry of these T&Cs: 5, 9, 10, 12, 15, 16, 17, 18, 19, 20, 21, 22, 25 and 28.</p>
              <p>1.7 Please read these T&Cs carefully and immediately terminate your Account if you do not agree.</p>
            </div>
          </EulaBlock>


          <EulaBlock>
            <h6>2 Your licence to use the Wagetap Platform</h6>
            <div>
              <p>2.1 We grant you a non-exclusive, royalty-free, revocable, worldwide, non-transferable licence to use the Wagetap Platform for your personal purposes, for the Term, in accordance with the T&Cs (<b>Licence</b>). All other uses are prohibited without our prior written consent.</p>
              <p>2.2 You agree that the Licence permits you to use the Wagetap Platform in accordance with these T&Cs.</p>
            </div>
          </EulaBlock>

          <EulaBlock>
            <h6>3 How does the Wagetap Platform work?</h6>
            <div>
              <p>3.1 Provided you comply with the T&Cs, through the Wagetap Platform, we (on behalf of your employer) are able to provide you with early access to a percentage of the pay you have earned from your employer but not yet been paid (your earned net after tax pay, or <b>Unpaid Earnings</b>) (the <b>Wagetap Services</b>).</p>
              <p>3.2 This means that instead of having to wait until the end of the pay cycle to get paid, for a small fee per request, you can now make unlimited requests to be paid your Unpaid Earnings before the date you would usually get paid by your employer.</p>
              <p>3.3 We may, in our sole discretion and on your employer’s behalf, pay you the amounts you request (each an Early Access Amount). Provided you comply with the T&C’s, you can expect to get these amounts within 1 day of making the request (but note that we do not make any promises as to this and you waiver and release us from any liability in relation to any delay).</p>
              <p>
                3.4 At the end of the pay cycle, your employer will pay:
              </p>
              <ul>
                <li>(a) you what you would have been paid for that pay cycle (your net after tax pay) minus any Early Access Amounts and applicable fees; and</li>
                <li>(b) us the deducted amounts.</li>
              </ul>
              <p>Your net after tax pay and any deductions for that paycycle will be set out in your payslip.</p>
            </div>
          </EulaBlock>

          <EulaBlock>
            <h6>4 What do we need from you?</h6>
            <div>
              <p className="margin-bottom-0">4.1 Your use of the Wagetap Platform is subject to you:</p>
              <ul>
                <li>(a) complying with these T&Cs;</li>
                <li>(b) providing the information and doing what is required to set up and register your account as being linked to your employer; and</li>
                <li>(c) agreeing to the terms of the direct debit agreement.</li>
              </ul>
              <p className="margin-bottom-0">4.2 In order to use the Wagetap Platform and Wagetap Services, you must be:</p>
              <ul>
                <li>(a) 18 years of age or older; and</li>
                <li>(b) an Australian resident or permanent citizen.</li>
              </ul>
              <p>By accepting these T&Cs, you warrant that both of these statements is true in relation to you.</p>
            </div>
          </EulaBlock>

          <EulaBlock>
            <h6>5 Authorisations</h6>
            <div>
              <p className="margin-bottom-0">5.1 By accepting these T&Cs, you authorise:</p>
              <ul>
                <li>(a) us to make direct debits; and</li>
                <li>(b) your employer to make deductions from your net after tax earnings,</li>
              </ul>
              <p>in accordance with these T&CS.</p>
            </div>
          </EulaBlock>

          <EulaBlock>
            <h6>6 How will you receive the Early Access Amounts?</h6>
            <div>
              <p>6.1 The Early Access Amounts will be paid by us, on behalf of your employer, using the payment details that you provide to your employer.</p>
              <p>6.2 You will be responsible for any incorrect payments that are as a result of you providing incorrect information to your employer or failing to update your employer of any changes.</p>
            </div>
          </EulaBlock>

          <EulaBlock>
            <h6>7 What % of your Unpaid Earnings can you access?</h6>
            <div>
              <p>7.1 The percentage of the Unpaid Earnings that you will be able to accesss through the Wagetap Platform could be up to 50%.</p>
              <p>7.2 The exact percentage will depend on any limitations that your employer places on your access.</p>
            </div>
          </EulaBlock>

          <EulaBlock>
            <h6>8 How much is the fee to access your Unpaid Earnings?</h6>
            <div>
              <p>8.1 Each time you make a request for an Early Access Amount (each an <b>Early Access Request</b>), you agree to pay a flat fee of $4.00 (inc GST) for that request (<b>Fee</b>). </p>
              <p>8.2 We may vary the Fee at any time by giving you notice in the Wagetap Platform.  If the Fee is varied, the varied Fee will apply on and from the end of the paycycle which you were notified. </p>
              <p>8.3To clarify, we will not be deducting the Fee from the Early Access Amounts that we pay to you. As set out in sections 3.4 and 9, the Fees will be deducted at the end of the paycycle, by your employer, from your net after tax pay (along with any Early Access Amounts).</p>
              <p className="margin-bottom-0">8.4 You agree that the Fees are non-refundable in all circumstances. This includes where:</p>
              <ul>
                <li>(a) a request was made by accident;</li>
                <li>(b) a request was made by someone other than you using your account (whether or not you had knowledge of this);</li>
                <li>(c) you have changed your mind; or</li>
                <li>(d) you are direct debited and / or required to pay back an Early Access Amount ahead of time.</li>
              </ul>
            </div>
          </EulaBlock>

          <EulaBlock>
            <h6>9 Your payment / repayment obligations</h6>
            <div>
              <p><b>General</b></p>
              <p>9.1 Any Early Access Amounts and Fees will be a due and payable to us by you (via your employer) at the end of the paycycle that the Early Access Requect is requested in.</p>
              <p>9.2 If any payment is not been made in full by your employer within 10 days of the end of the paycycle, we may direct debit all or part of the payment amounts from you directly (in accordance with the Direct Debit Agreement).</p>
              <p className="margin-bottom-0">9.3 If we are unable to direct debit any payment amount from you for whatever reason, this will be considered a failure by you to pay and we may (at our absolute discretion):</p>
              <ul>
                <li>(a) recover, as a debt due and immediately payable from you, the payment amount and our additional costs of doing so; and</li>
                <li>(b) on and from 30 days after the payment date, charge interest at a rate equal to 3% above the benchmark interest rate set by the Australian Tax Office, from time to time.</li>
              </ul>
              <p><b>Exceptional circumstances</b></p>
              <p className="margin-bottom-0">9.4 You agree that we may (in our sole discretion and in accordance with the Direct Debit Agreement) direct debit part of or all of an amount paid to you by us, at any time and without providing prior notice, in the following circumstances: </p>
              <ul>
                <li>(a) where a payment was made to you in error (including where a payment that was not requested by you or you were paid an amount that is greater than the amount you requested); </li>
                <li>(b) where we become aware that are no longer employed by your employer;</li>
                <li>(c) where we suspect any sort of fraud or misconduct on the part of you or your employer; and</li>
                <li>(d) where we suspect that you employer is experiencing or is likely to, in the short term, experience financial difficulty. </li>
              </ul>
              <p className="margin-bottom-0">9.5 If we are unable to direct debit the payment amounts from you for whatever reason, this will be considered a failure by you to pay and we may we may (at our absolute discretion):</p>
              <ul>
                <li>(a) recover, as a debt due and immediately payable from you, the payment amount and our additional costs of doing so; and</li>
                <li>(b) on and from 30 days after the payment date, charge interest at a rate equal to 3% above the benchmark interest rate set by the Australian Tax Office, from time to time.  </li>
              </ul>
            </div>
          </EulaBlock>

          <EulaBlock>
            <h6>10 Availability of the Wagetap Platform and Wagetap Services</h6>
            <div>
              <p>10.1 We may, at any time and without notice to you, discontinue the Wagetap Platform and Wagetap Services, in whole or in part.</p>
              <p>10.2 We may also exclude any person from using the Wagetap Platform and Wagetap Services (including by refusing to pay an Early Access Amount or refusing an Early Access Request), at any time and for any length of time, at our sole discretion. </p>
              <p>10.3 We are not responsible for, and you waive and release us from and against, any Liability you may suffer arising from or in connection with any such discontinuance or exclusion.</p>
            </div>
          </EulaBlock>

          <EulaBlock>
            <h6>11 Registration and account</h6>
            <div>
              <p>11.1 You will be required to create an account with us in order to access and use the Wagetap Platform (<b>Account</b>). You must ensure that any information you give us when creating an Account, or using the Wagetap Platform, is accurate, complete and up-to-date.</p>
              <p>11.2 When you create an Account, you will be able to choose or the Wagetap Platform will generate certain Account details (such as a username and password). It is your responsibility to keep your Account details confidential. You are liable for all content posted and all activity on your Account and you must immediately notify us of any unauthorised use of your Account.</p>
            </div>
          </EulaBlock>

          <EulaBlock>
            <h6>12 AML/CTF and other relevant laws</h6>
            <div>
              <p className="margin-bottom-0">12.1 You acknowledge and agree that: </p>
              <ul>
                <li>(a) we must undertake customer identification, verification and monitoring under the <i>Anti-Money Laundering and Counter-Terrorism Financing Act 2006</i> (Cth), and the related Rules (<b>AML Laws</b>), and may take any action (or inaction) that we consider necessary to comply with the AML Laws, including suspending or terminating your Account; </li>
                <li>(b) you will provide all information and documents reasonably requested by us to enable us to comply with the relevant laws in Australia or any country; and </li>
                <li>(c) we may disclose information about you, or your transactions for any regulatory or legal purpose. </li>
              </ul>
            </div>
          </EulaBlock>

          <EulaBlock>
            <h6>13 Where does the information on the Wagetap Platform come from?</h6>
            <div>
              <p>13.1 All of the information on the Wagetap Platform (including any representation as to your Unpaid Earnings) (Content) comes from data in your employer’s software and systems.</p>
              <p>13.2 If you have any issues, queries or concerns in relation to the Content, you should speak directly with your employer.</p>
            </div>
          </EulaBlock>

          <EulaBlock>
            <h6>14 Technical problems</h6>
            <div>
              <p>14.1 In case of any technical problems, you must make all reasonable efforts to investigate and diagnose problems before contacting us.</p>
              <p>14.2 If you still need help, please check the support we provide as stated on Wagetap Platform or failing that please email us at <a className="underline" href="mailto:support@wagetap.com">support@wagetap.com</a>.</p>
            </div>
          </EulaBlock>

          <EulaBlock>
            <h6>15 Conduct we don’t accept </h6>
            <div>
              <p>15.1 When you use the Wagetap Platform, we expect you to abide by a certain standards of behaviour.</p>
              <p className="margin-bottom-0">15.2 You must not:</p>
              <ul>
                <li>(a) do or attempt to do anything that is unlawful, which is prohibited by any laws applicable to the Wagetap Platform, which we would consider inappropriate or which might bring us or the Wagetap Platform / our website into disrepute;</li>
                <li>(b) use the Wagetap Platform and Wagetap Services in any way that damages, interferes with or interrupts the supply of the Wagetap Patform and Wagetap Services;</li>
                <li>(c) introduce malicious programs into our hardware and software or systems, including viruses, malware worms, trojan horses and e-mail bombs;</li>
                <li>(d) reveal or allow others access to your account’s password or authentication details or allow others to use your account or authentication details;</li>
                <li>(e) carry out security breaches or disruptions of a network, including accessing data where you are not the intended recipient or logging into a server or account that you are not expressly authorised to access or corrupting any data (including network sniffing/monitoring, pinged floods, packet spoofing, denial of service and forged routing information for malicious purposes); and</li>
                <li>(f) circumvent user authentication or security of any of our Services, networks, accounts or hosts or those of our other users.</li>
              </ul>
            </div>
          </EulaBlock>

          <EulaBlock>
            <h6>16 Intellectual property</h6>
            <div>
              <p>16.1 Unless otherwise indicated, we own or licence all rights, title and interest (including intellectual property rights) in the Wagetap Platform and Wagetap Services.</p>
              <p>16.2 All intellectual property (including copyright) developed, adapted, modified or created by us or our personnel (including in connection with these T&Cs, the Wagetap Platform and Wagetap Services) will at all times vest, or remain vested, in us.</p>
              <p className="margin-bottom-0">16.3 You must not whether directly or indirectly, without our prior written consent: </p>
              <ul>
                <li>(a) copy or use, in whole or in part, any of our Intellectual Property; </li>
                <li>(b) reproduce, retransmit, distribute, disseminate, sell, publish, broadcast or circulate any of our Intellectual Property to any third party;</li>
                <li>(c) reverse assemble, reverse engineer, reverse compile or enhance the Services;</li>
                <li>(d) attempt to discover the source code or object code or underlying structures, ideas, know how or algorithms in relation to the Services, the data or documentation; </li>
                <li>(e) breach any Intellectual Property Rights connected with the Wagetap Platform and Wagetap Services, including altering or modifying any of our Intellectual Property; </li>
                <li>(f) cause any of our Intellectual Property to be framed or embedded in another website; or create derivative works from any of our Intellectual Property;</li>
                <li>(g) resell, assign, lease, hire, sub-license, transfer, distribute or make available the Services to third parties;</li>
                <li>(h) "frame", "mirror" or serve any of the Services on any web server or other computer server over the Internet or any other network; and</li>
                <li>(i) alter, remove or tamper with any trademarks, any patent or copyright notices, any confidentiality legend or notice, any numbers or any other means of identification used on or in relation to the Services.</li>
              </ul>
            </div>
          </EulaBlock>

          <EulaBlock>
            <h6>17 Privacy</h6>
            <div>
              <p>17.1 We respect your privacy and understand protecting your personal information is important. Our privacy policy (available at <a href="//www.wagetap.com">www.wagetap.com</a>) sets out how we will collect and handle your personal information.</p>
              <p className="margin-bottom-0">17.2 On acceptance of these T&Cs, you acknowledge and consent to us:</p>
              <ul>
                <li>(a) having access to information (including personal information) provided by you to your employer for the purposes of payment; and</li>
                <li>(b) using any information (including personal information) provided by you to us,</li>
              </ul>
              <p>for the purposes allowing you to access and use the Wagetap Platform and Wagetap Services.</p>
            </div>
          </EulaBlock>

          <EulaBlock>
            <h6>18 Australian Consumer Law</h6>
            <div>
              <p>18.1 Certain legislation, including the Australian Consumer Law, and similar consumer protection laws and regulations, may confer you with rights, warranties, guarantees and remedies relating to the provision of the Wagetap Services by us to you which cannot be excluded, restricted or modified (<b>Statutory Rights</b>). </p>
              <p>18.2 For the purposes of these T&Cs, “<b>Australian Consumer Law</b>” or <b>“ACL”</b> means the Australian consumer laws set out in Schedule 2 of the <i>Competition and Consumer Act 2010</i> (Cth), as amended, from time to time.</p>
              <p>18.3 If the ACL applies to you as a consumer, nothing in these T&Cs excludes your Statutory Rights as a consumer under the ACL. You agree that our Liability for Wagetap Services provided to an entity defined as a consumer under the ACL is governed solely by the ACL and these T&Cs.  </p>
              <p>18.4 Subject to your Statutory Rights, we exclude all express and implied warranties, and all material, work and services (including the Wagetap Services) are provided to you without warranties of any kind, either express or implied, whether in statute, at Law or on any other basis.</p>
            </div>
          </EulaBlock>

          <EulaBlock>
            <h6>19 Warranties and disclaimers</h6>
            <div>
              <p className="margin-bottom-0">19.1 To the maximum extent permitted by law, we make no representations or warranties about the Wagetap Platform, Wagetap Services or the Content, including (without limitation) that:</p>
              <ul>
                <li>(a) they are complete, accurate, reliable, up-to-date and suitable for any particular purpose;</li>
                <li>(b) access will be uninterrupted, error-free or free from viruses; or</li>
                <li>(c) the Wagetap Platform will be secure.</li>
              </ul>
              <p>19.2 You read, use and act on the Wagetap Platform, Wagetap Services and any Content at your own risk.</p>
            </div>
          </EulaBlock>

          <EulaBlock>
            <h6>20 Our liability is limited</h6>
            <div>
              <p className="margin-bottom-0">20.1 You acknowledge and agree that:</p>
              <ul>
                <li>(a) your relationship with us is limited to what is set out in these T&Cs;</li>
                <li>(b) there is no employment relationship between us and you; and</li>
                <li>(c) we are not obligated to make any payments of any kind to you.</li>
              </ul>
              <p className="margin-bottom-0">20.2 To the maximum extent permitted by law, and subject to your Statutory Rights, we are not responsible for and you waive, indemnify and hold us harmless against, any loss, damage or expense, howsoever arising, whether direct or indirect and/or whether present, unascertained, future or contingent (Liability) suffered by you or any third party, arising from or in connection with:</p>
              <ul>
                <li>(a) your use of the Wagetap Platform and/or Wagetap Services and/or Content (including claims by third parties in relation to this);</li>
                <li>(b) any inaccessibility of, interruption to or outage of the Wagetap Platform and/or Wagetap Services;</li>
                <li>(c) any loss or corruption of data;</li>
                <li>(d) the Content or information provided by you to us being incorrect, incomplete or out-of-date; and</li>
                <li>(e) any claim (including by a court or tribunal) that there is a relationship between us and you (current and historical) and that you are entitled you to payment of any kind from us.</li>
              </ul>
              <p>20.3 For the purposes of these T&Cs, “<b>Statutory Rights</b>” has the meaning given in section 18.1.</p>
              <p>20.4 Despite anything to the contrary and to the maximum extent permitted by law, neither you nor us will be liable for any Consequential Loss. </p>
              <p><b>“Consequenctial Loss”</b> includes any consequential loss, indirect loss, real or anticipated loss of profit, loss of benefit, loss of revenue, loss of business, loss of goodwill, loss of opportunity, loss of savings, loss of reputation, loss of use and/or loss or corruption of data, whether under statute, contract, equity, tort (including negligence), indemnity or otherwise.</p>
            </div>
          </EulaBlock>

          <EulaBlock>
            <h6>21 Indemnity</h6>
            <div>
              <p>21.1 To the maximum extent permitted by law, you must indemnify us, and hold us harmless, against any Liability suffered or incurred by us arising from or in connection with your use of the Wagetap Platform and/or Wagetap Services and/or Content or information provided by you to us or any breach of these T&Cs or any applicable laws by you. This indemnity is a continuing obligation, independent from the other obligations under these T&Cs, and continues after these T&Cs end.</p>
              <p>21.2 It is not necessary for us to suffer or incur any Liability before enforcing a right of indemnity under these T&Cs.</p>
            </div>
          </EulaBlock>

          <EulaBlock>
            <h6>22 What happens on termination?</h6>
            <div>
              <p className="margin-bottom-0">22.1 On termination of your Account: </p>
              <ul>
                <li>(a) these T&Cs will also terminate;</li>
                <li>(b) your Account will be permanently disabled; </li>
                <li>(c) the Licence will end; and</li>
                <li>(d) you agree that any Early Access Amounts and Fees paid to by us to you will be a debt immediately due and payable. </li>
              </ul>
              <p>22.2 All accrued rights, obligations and remedies of the you and us are not affected by termination of these T&Cs.</p>
            </div>
          </EulaBlock>

          <EulaBlock>
            <h6>23 GST</h6>
            <div>
              <p>23.1 You agree to pay any GST amount at the same time as you pay the Fees.</p>
            </div>
          </EulaBlock>

          <EulaBlock>
            <h6>24 Who should you take any issues to?</h6>
            <div>
              <p>24.1 If you experience any issues that relate to your ability access/use to the Wagetap Platform, the Content or the Wagetap Services, you should raise these issues directly with your employer.</p>
              <p className="margin-bottom-0">Some examples include where you:</p>
              <ul>
                <li>(a) don’t think the amount of Unpaid Earnings shown on the Wagetap Platform is correct;</li>
                <li>(b) are unable or are restricted from making a request on the Wagetap Platform; and</li>
                <li>(c) are unable to request the amount you would like to request through the Wagetap Platform.</li>
              </ul>
              <p>24.2 Where an issue relates to these T&Cs, or a payment or direct debit by us, you should raise this issue with us directly.</p>
              <p>24.3 If you are not sure where to take an issue, we recommend that you take it to your employer in the first instance (and they can always seek our input or redirect you to us).</p>
            </div>
          </EulaBlock>

          <EulaBlock>
            <h6>25 How will issues with us be dealt with?</h6>
            <div>
              <p>25.1 In the event of any dispute arising from, or in connection with, these T&Cs (<b>Dispute</b>), the party claiming there is a Dispute must give written notice to the other party setting out the details of the Dispute and proposing a resolution. </p>
              <p>25.2 Within 7 days after receiving the notice, the parties must, by their senior executives or senior managers (who have the authority to reach a resolution on behalf of the party), meet at least once to attempt to resolve the Dispute or agree on the method of resolving the Dispute by other means, in good faith. All aspects of every such conference, except the fact of the occurrence of the conference, will be privileged. </p>
              <p>25.3 If the parties do not resolve the Dispute, or (if the Dispute is not resolved) agree on an alternate method to resolve the Dispute, within 21 days after receipt of the notice, the Dispute may be referred by either party (by notice in writing to the other party) to litigation.</p>
            </div>
          </EulaBlock>

          <EulaBlock>
            <h6>26 Will these T&Cs change?</h6>
            <div>
              <p>26.1 We may, at any time and at our discretion, vary these T&Cs by publishing varied terms and conditions on the Wagetap Platform. Such variation will take effect at the commencement of your next plan billing cycle. </p>
              <p>26.2 Prior to the commencement of each plan billing cycle, you must carefully read the terms and conditions that are in effect at that time to ensure you understand and agree to them.</p>
            </div>
          </EulaBlock>

          <EulaBlock>
            <h6>27 What happens if a part of these T&Cs isn’t right?</h6>
            <div>
              <p>27.1 If a provision of these T&Cs is held to be void, invalid, illegal or unenforceable, that provision must be read down as narrowly as necessary to allow it to be valid or enforceable. </p>
              <p>27.2 If it is not possible to read down a provision (in whole or in part), that provision (or that part of that provision) is severed from these T&Cs without affecting the validity or enforceability of the remainder of that provision or the other provisions in these T&Cs.</p>
            </div>
          </EulaBlock>

          <EulaBlock>
            <h6>28 Which laws govern these T&Cs?</h6>
            <div>
              <p>28.1 Your use of the Wagetap Platform, the Wagetap Services and these T&Cs are governed by the laws of New South Wales. </p>
              <p>28.2 The Wagetap Platform and Wagetap Services may be accessed throughout Australia and overseas. We make no representation that the Wagetap Platform and Wagetap Services comply with the laws (including intellectual property laws) of any country outside Australia.  If you access the Wagetap Platform or Wagetap Services from outside Australia, you do so at your own risk and are responsible for complying with the laws of the jurisdiction where you access the Wagetap Platform and/or Wagetap Services.</p>
            </div>
          </EulaBlock>

          <EulaBlock>
            <h6>For any questions, please contact us at:</h6>
            <div>
              <p><b>Digital Services Australia V Pty Ltd</b> (ABN 33 638 924 339)</p>
              <p>Email: <a href="mailto:support@wagetap.com" className="underline">support@wagetap.com</a></p>
              <p>Last update: 12 May 2020</p>
            </div>
          </EulaBlock>
        </Container>
      </div >
    );
  }
}

const EulaBlock = (props) => (
  <div className={styles.eulaBlock}>{props.children}</div>
)
